<!--选择供应地区组件-->
<template>
  <div>
    <el-row style="margin-bottom: 10px;">
      <el-col :span="12">
        <template v-if="!isNotEmpty(areaLimit)">
          <el-button type="primary" size="mini" @click="supplyArea=allAreaNameFilterGat">全选(不包含港澳台)</el-button>
<!--          <el-button type="success" size="mini" @click="supplyArea=area4_7">4+7</el-button>-->
<!--          <el-button type="success" size="mini" @click="supplyArea=areaGd">广东联盟</el-button>-->
<!--          <el-button type="success" size="mini" @click="supplyArea=areaCsj">长三角联盟</el-button>-->
        </template>
        <el-button type="info" size="mini" @click="supplyArea=[]">清空</el-button>
        <el-link type="primary" style="margin-left: 10px;">已选中{{supplyArea.length}}个</el-link>
      </el-col>
      <el-col :span="8">
        <el-input placeholder="粘贴供应地区信息执行解析"  clearable
                  @keydown.enter.native="analysisSupplyAreaStr()"
                  v-model="supplyAreaStr" style="min-width: 200px;"></el-input>

        <el-input v-show="false"></el-input>
      </el-col>
      <el-col :span="4" style="padding-left: 4px;">
        <el-button type="primary" size="mini"  @click="analysisSupplyAreaStr(2)">解析</el-button>
      </el-col>




    </el-row>
    <el-checkbox-group v-model="supplyArea">
      <el-table
        :data="isNotEmpty(areaLimit)?limitAreaTableData:areaTableData"
        size="mini"
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        style="width: 100%">
        <el-table-column prop="orderNo" label="排序" width="80"></el-table-column>
        <el-table-column prop="area" label="省份" min-width="100">
          <template slot-scope="scope">
            <el-checkbox v-for="item in scope.row.area" :label="item.code" style="float: left;">{{ item.name }}</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="orderNo2" label="排序" width="80"></el-table-column>
        <el-table-column prop="area2" label="省份" min-width="100">
          <template slot-scope="scope">
            <el-checkbox v-for="item in scope.row.area2" :label="item.code" style="float: left;">{{ item.name }}</el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </el-checkbox-group>
  </div>

</template>
<script>


import { isEmpty } from '@/utils/common'

export default {
  name: 'selectSupplyArea',
  computed: {
    supplyArea: {
      get() {
        //console.log("get-supplyArea",this.value)
        if(isEmpty(this.value)){
          return []
        }
        return this.value.split(",");
      },
      set(v) {
        //console.log("set-supplyArea",v)
        if(v){
          this.$emit('input',v.toString());
        }else{
          this.$emit('input',"");
        }
      }
    },
    allAreaName: function () {
      let allArea = []
      this.areaDict.forEach((item, index) => {
        item.area.forEach(t=>{
          allArea.push(t.code)
        })

      })
      return allArea
    },
    //全部地区不包含港澳台
    allAreaNameFilterGat: function () {
      let allArea = []
      this.areaDict.forEach((item, index) => {
        item.area.forEach(t=>{
          if(['香港','澳门','台湾'].indexOf(t.name)<0){
            allArea.push(t.code)
          }
        })
      })
      return allArea
    },
    //省市地区字典重新排列
    areaTableData: function () {
      let _this = this
      let areaTableList = this.areaDict.slice(0, 8)
      areaTableList.forEach((item, index) => {
        let next = _this.areaDict[index + 8]
        item.orderNo2 = next.orderNo
        item.area2 = next.area
      })
      return areaTableList
    },
    //过滤并重新排列省市地区字典重新排列
    limitAreaTableData: function () {
      let _this = this
      let limitAreaDict =[]
      this.areaDict.forEach(item=>{
        let areaRow={
          orderNo: item.orderNo, area:[]
        }
        item.area.forEach(areaItem=>{
          if(_this.areaLimit.indexOf(areaItem.code)>=0){
            areaRow.area.push(areaItem)
          }
        })
        if( areaRow.area.length>0){
          limitAreaDict.push(areaRow)
        }
      })
      //组合成两列
      if(limitAreaDict.length%2>0){
        limitAreaDict.push({orderNo: "", area:[]})
      }
      let areaTableList = limitAreaDict.slice(0, limitAreaDict.length/2)
      areaTableList.forEach((item, index) => {
        let next =limitAreaDict[index + limitAreaDict.length/2]
        item.orderNo2 = next.orderNo
        item.area2 = next.area
      })
      return areaTableList
    },
    //4+7
    area4_7: function () {
      return [
        "110000",
        "120000",
        "310000",
        "500000",
        "210100",
        "210200",
        "350200",
        "440100",
        "440300",
        "510100",
        "610100"
      ]
    },
    //广东联盟
    areaGd: function () {
      return [
        "440000",
        "140000",
        "360000",
        "410000",
        "450000",
        "460000",
        "520000",
        "630000",
        "640000",
        "650000",
        "530000",
        "150000",
      ]
    },
    //长三角联盟
    areaCsj: function () {
      return [
        "310000",
        "330000",
        "340000",
      ]
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    //限制范围
    areaLimit: {
      type: String,
      default: ''
    },
  },
  watch: {

  },
  data() {
    return {
      supplyAreaStr:'',
      areaDict: [
        {orderNo: "A", area: [{code:"340000",name:"安徽"}, {code:"820000",name:"澳门"}]},
        {orderNo: "B", area: [{code:"110000",name:"北京"}]},
        {orderNo: "C", area: [{code:"500000",name:"重庆"}, {code:"510100",name:"成都"}]},
        {orderNo: "D", area: [{code:"210200",name:"大连"}]},
        {orderNo: "F", area: [{code:"350000",name:"福建"}]},
        {orderNo: "G", area: [{code:"440000",name:"广东"},{code:"620000",name:"甘肃"} , {code:"520000",name:"贵州"},{code:"450000",name:"广西"},{code:"440100",name:"广州"}]},
        {orderNo: "H", area: [{code:"430000",name:"湖南"}, {code:"420000",name:"湖北"}, {code:"230000",name:"黑龙江"}, {code:"130000",name:"河北"}, {code:"410000",name:"河南"}, {code:"460000",name:"海南"}]},
        {orderNo: "J", area: [{code:"320000",name:"江苏"}, {code:"220000",name:"吉林"}, {code:"360000",name:"江西"}]},
        {orderNo: "L", area: [{code:"210000",name:"辽宁"}]},
        {orderNo: "N", area: [{code:"150000",name:"内蒙古"}, {code:"640000",name:"宁夏"}]},
        {orderNo: "Q", area: [{code:"630000",name:"青海"}]},
        {orderNo: "S", area: [{code:"310000",name:"上海"},{code:"370000",name:"山东"} , {code:"610000",name:"陕西"}, {code:"140000",name:"山西"}, {code:"510000",name:"四川"},{code:"210100",name:"沈阳"},{code:"440300",name:"深圳"}]},
        {orderNo: "T", area: [{code:"120000",name:"天津"}, {code:"710000",name:"台湾"}]},
        {orderNo: "X", area: [{code:"650000",name:"新疆"},{code:"650001",name:"新疆生产建设兵团"}, {code:"540000",name:"西藏"},{code:"810000",name:"香港"} ,{code:"350200",name:"厦门"} ,{code:"610100",name:"西安"}]},
        {orderNo: "Y", area: [{code:"530000",name:"云南"}]},
        {orderNo: "Z", area: [{code:"330000",name:"浙江"}]},
      ],
      areaMap:{},

    }
  },
  created() {
    // 地区 代码 和名称映射关系
    this.areaMap=new Map()
    let areaMap=this.areaMap
    this.areaDict.forEach((item, index) => {
      item.area.forEach(t=>{
        areaMap.set(t.code,t.name)
      })
    })

  },
  mounted() {

  },
  methods: {
    isNotEmpty(str){
      return !isEmpty(str)
    },
    //解析复制粘贴的供应区域信息
    analysisSupplyAreaStr(){
      if(isEmpty(this.supplyAreaStr)){
        this.msgInfo("请先输入供应地区信息")
        return
      }
      let supplyAreaStr=this.supplyAreaStr.replaceAll(" ","")
      let supplyArea=this.supplyArea
      let index=0
      this.areaDict.forEach(item=>{
        item.area.forEach(areaItem=>{
          if(supplyAreaStr.indexOf(areaItem.name)>=0 && supplyArea.indexOf(areaItem.code)<0){
            supplyArea.push(areaItem.code)
            index++
          }
        })
      })
      if(index>0){
        this.msgSuccess("解析成功["+index+"]个供应地区")
      }else{
        this.msgInfo("没有解析到有效的供应地区")
      }
      this.supplyArea=supplyArea

    }


  }
}
</script>
<style lang="scss" scoped>

</style>
