<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">招采批次管理</el-col>
        <el-col :span="20">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="" prop="searchValue">
              <el-input v-model="queryParams.searchValue"
                        placeholder="请输入采购文件编号/名称" maxlength="50" clearable
                        @keydown.enter.native="handleQuery(1)"
                         style="width: 300px;"></el-input>
            </el-form-item>

            <el-form-item label="发布日期:">
              <el-date-picker
                v-model="queryParams.dateRange"
                size="small"
                style="width: 240px"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery(1)">查询</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" @click="handleEdit()" class="addClass">新增
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        highlight-current-row
        @sort-change="tableSortChange"
      >
        <el-table-column type="index" min-width="50" label="序号"></el-table-column>
        <el-table-column min-width="100" property="docNum" label="采购文件编号"></el-table-column>
        <el-table-column min-width="200" property="docName" label="采购文件名称"></el-table-column>
        <el-table-column width="120" property="publishTime" label="发布日期" sortable="custom"></el-table-column>
        <el-table-column width="120" property="effectiveTime" label="生效日期" ></el-table-column>
        <el-table-column min-width="100" property="updateTime" label="最近操作时间"></el-table-column>
        <el-table-column  label="操作" min-width="100"  :resizable="false">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row.id)" type="text" size="small">编辑</el-button>
            <el-button type="text"  @click="handleDel(scope.row)" size="small" style="color: #F56C6C;">删除</el-button>
            <el-button @click="handleEditDrugList(scope.row.id)" type="text" size="small">药品目录</el-button>
            <el-button @click="handleEditReplaceDrugList(scope.row.id)" type="text" size="small">可替代目录</el-button>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>


    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="handleQuery"
      :page-sizes="[10, 20, 50, 100]"
    />

    <el-dialog v-dialogDrag title="招采批次信息编辑" width="80%"
               :visible.sync="recordEditDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="recordEditForm" status-icon :model="recordEditFormData" label-width="140px">
        <el-form-item label="采购文件编号:" prop="docNum"
                      :rules="[{ required: true, message: '请输入采购文件编号', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入采购文件编号" maxlength="100" clearable show-word-limit
                    v-model="recordEditFormData.docNum"
                    style="min-width: 300px;"
          />
        </el-form-item>

        <el-form-item label="采购文件名称:" prop="docName"
                      :rules="[{ required: true, message: '请输入采购文件名称', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入采购文件名称" maxlength="200" clearable show-word-limit
                    v-model="recordEditFormData.docName"
                    style="min-width: 300px;"
          />
        </el-form-item>

        <el-row>
          <el-col :span="8">
            <el-form-item label="类型:" prop="batchType"
                          :rules="[{ required: true, message: '请选择类型', trigger: 'blur'}]"
            >
              <el-select v-model="recordEditFormData.batchType"
                         style="min-width: 100px;width: 100%;"
                         filterable :disabled="recordEditFormData.id?true:false"
                         @change="batchTypeChange"
              >
                <el-option label="国采" :value='1'></el-option>
                <el-option label="联盟" :value='2'></el-option>

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发布日期:" prop="publishTime"
                          :rules="[{ required: true, message: '请选择发布日期', trigger: 'blur'}]"
            >
              <el-date-picker
                style="min-width: 100px;width: 100%;"
                v-model="recordEditFormData.publishTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择发布日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生效日期:" prop="effectiveTime"
                          :rules="[{ required: false, message: '请选择生效日期', trigger: 'blur'}]"
            >
              <el-date-picker
                style="min-width: 100px;width: 100%;"
                v-model="recordEditFormData.effectiveTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择生效日期">
              </el-date-picker>
            </el-form-item>

          </el-col>
        </el-row>


        <el-form-item label="联盟地区:">
          <select-supply-area v-model="recordEditFormData.supplyArea" ></select-supply-area>
        </el-form-item>






        <el-form-item label="文件附件:" prop="attachmentFiles"
        >
          <el-upload style="float: left;width: calc(100% - 60px)"
                     :action="baseURL+'/system/file/uploadFile'"
                     :file-list="uploadFileList"
                     :data="uploadParams"
                     :on-preview="handleFilePreview"
                     accept=".bmp, .gif, .jpg, .jpeg, .png, .pdf"
                     :auto-upload="true"
                     :before-upload="beforeUpload"
                     :on-success="(res, file, fileList)=>{uploadSuccess(res, file, fileList)}"
                     list-type="picture-card">

            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}" style="text-align: center">
              <p style="position: absolute;">{{file.name}}</p>
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url" :alt="file.name"
                style="padding-top: 30px;width: 80%;"
              >
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handleFilePreview(file)"
                ><i class="el-icon-download">下载</i>
                </span>
                <span
                  class="el-upload-list__item-delete"
                  @click="handleFileRemove(file)"
                ><i class="el-icon-delete">删除</i>
                </span>
              </span>
            </div>
            <img src="../../../assets/images/pdf.png" id="pdfImage" v-show="false">
            <div slot="tip" class="el-upload__tip">只能上传bmp/gif/jpg/jpeg/png/pdf文件，且大小不超过50MB</div>
          </el-upload>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="recordEditDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditRecord">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import selectSupplyArea from '@/views/dkm/purchaseBatch/components/selectSupplyArea'
import AcrossUtil from '@/utils/acrossUtil'
import {isEmpty,axiosDownload} from '@/utils/common'
import {recordList, editRecord, recordInfo} from '@/api/dkm/purchaseBatchRecord'
import {delFile,downloadFile} from '@/api/system/fileUpload'
export default {
  name: 'RecordList',
  components: {selectSupplyArea },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue:"",
        dateRange: [],
        orderByColumn: undefined,
        isAsc: undefined
      },
      // 批次信息编辑弹窗
      recordEditDialogVisible: false,
      // 批次信息编辑表单
      recordEditFormData: {},
      //上次选中的地区，用于比较地区是否变化
      lastSupplyArea:"",
      //上传文件列表
      uploadFileList: [],
      //上传文件额外参数
      uploadParams: {
        fileClassify: 5,
        tempFlag: 1
      },




    }
  },
  created() {
    this.handleQuery(1)

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    /*查询操作*/
    handleQuery(type) {
      if (type == 1) {
        //重新执行查询，从第一页开始
        this.queryParams.pageNum = 1
      }
      this.queryParams.beginTime=''
      this.queryParams.endTime=''
      let params = this.queryParams
      if (params.dateRange && params.dateRange.length == 2) {
        params.beginTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      }
      //this.queryParams.beginTime=''
      //this.queryParams.endTime=''
      this.loading = true
      recordList(params).then(res => {
        this.loading = false
        res.rows.forEach(row => {

        })
        this.tableDataList = res.rows
        this.total = res.total
      })
    },
    //处理新增、修改
    handleEdit(recordId) {
      this.uploadFileList=[]
      if (recordId) {
        //编辑
        recordInfo(recordId).then(res => {
          this.recordEditFormData = res.data
          this.lastSupplyArea= res.data.supplyArea
          let attachmentFiles=res.data.attachmentFiles
          if(!isEmpty(attachmentFiles)){
            this.uploadFileList=JSON.parse(attachmentFiles)
          }
        })
      } else {
        //新增
        this.recordEditFormData = {
          supplyArea:"",
          batchType:1,

        }
      }
      this.recordEditDialogVisible = true

    },
    handleDel(id){
      this.msgInfo("暂不支持删除批次记录数据")
    },
    //查看药品目录
    handleEditDrugList(recordId){
      this.$router.push('/purchaseBatch/recordDrugList/' + recordId)
    },
    //查看可替代药品目录
    handleEditReplaceDrugList(recordId){
      this.$router.push('/purchaseBatch/replaceDrugList/' + recordId)
    },
    //确定编辑
    submitEditRecord() {
      this.$refs['recordEditForm'].validate(valid => {
        this.recordEditFormData.attachmentFiles=JSON.stringify(this.uploadFileList)
        if (valid) {
          if(this.recordEditFormData.id && !isEmpty(this.lastSupplyArea)
            && this.lastSupplyArea!=this.recordEditFormData.supplyArea){
            this.$confirm('默认供应区域已变化,是否使用新的默认供应区域替换当前批次全部已维护药品的供应区域？', '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning'
            }).then(() => {
              this.recordEditFormData.updateDrugSupplyArea=true
              this.doEditRecord()
            }).catch(() => {
              this.recordEditFormData.updateDrugSupplyArea=false
              this.doEditRecord()
            });
          }else{
            this.doEditRecord()
          }
        } else {
          this.msgError('请规范填写')
        }
      })


    },
    doEditRecord(){
      editRecord(this.recordEditFormData).then(res => {
        this.msgSuccess(res.msg)
        this.recordEditDialogVisible = false
        this.handleQuery(1)
      })
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    // 处理文件预览预览
    handleFilePreview(file) {
      downloadFile(file.fileId).then(res=>{
        axiosDownload(file.name, res)
      })

    },
    // 文件上传前校验
    beforeUpload(file) {
      if (file.size / 1024 / 1024 > 50) {
        this.$message.error('上传文件大小不能超过 50MB');
        return false;
      }
    },
    //移除文件
    handleFileRemove(file) {
      let fileIndex = -1
      this.uploadFileList.some((t, i) => {
        if (t.uid == file.uid) {
          fileIndex = i
          return true
        }
      })
      this.uploadFileList.splice(fileIndex, 1)
      // 调用接口删除文件记录
      //delFile(file.fileId)
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.msgSuccess("上传成功")
        let url = this.baseURL + '/system/file/preview?id=' + res.data.id
        let previewUrl=this.baseURL + '/system/file/preview?id=' + res.data.id
        let fileType = file.raw.type
        if (fileType == "application/pdf") {
          url = document.getElementById("pdfImage").src
        }
        let fileItem = {
          fileId: res.data.id,
          name: file.name,
          url: url,
          previewUrl:previewUrl,
          status: "success",
          uid: file.uid
        }
        this.uploadFileList.push(fileItem)
      } else {
        this.msgError(res.msg || '上传失败')
        //移除预览文件
        let fileIndex = -1
        fileList.some((t, i) => {
          if (t.uid == file.uid) {
            fileIndex = i
            return true
          }
        })
        fileList.splice(fileIndex, 1)
      }
    },
    //类型下拉列表值变化
    batchTypeChange(val){
      console.log("batchTypeChange",val)
      if( !this.recordEditFormData.id){
        // if(val==2){
        //   //广东联盟
        //   this.recordEditFormData.supplyArea="440000,140000,360000,410000,450000,460000,520000,630000,640000,650000,530000,150000";
        // }else if(val==3){
        //   //长三角联盟
        //   this.recordEditFormData.supplyArea="310000,330000,340000";
        // }else{
        //   //国采
        //   this.recordEditFormData.supplyArea="";
        // }
        this.recordEditFormData.supplyArea="";

      }
    }

    // 远程搜索标签名称
    // queryLabelList(query) {
    //   if (!isEmpty(query)) {
    //     this.loading = true
    //     selectActionLabelList(query.trim()).then(res => {
    //       this.labelList = res.data
    //       this.loading = false
    //     })
    //   } else {
    //     this.labelList = []
    //   }
    // }

  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}


</style>


<style>


</style>
