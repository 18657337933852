var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              !_vm.isNotEmpty(_vm.areaLimit)
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.supplyArea = _vm.allAreaNameFilterGat
                          },
                        },
                      },
                      [_vm._v("全选(不包含港澳台)")]
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "info", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.supplyArea = []
                    },
                  },
                },
                [_vm._v("清空")]
              ),
              _c(
                "el-link",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                },
                [_vm._v("已选中" + _vm._s(_vm.supplyArea.length) + "个")]
              ),
            ],
            2
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-input", {
                staticStyle: { "min-width": "200px" },
                attrs: {
                  placeholder: "粘贴供应地区信息执行解析",
                  clearable: "",
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.analysisSupplyAreaStr()
                  },
                },
                model: {
                  value: _vm.supplyAreaStr,
                  callback: function ($$v) {
                    _vm.supplyAreaStr = $$v
                  },
                  expression: "supplyAreaStr",
                },
              }),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticStyle: { "padding-left": "4px" }, attrs: { span: 4 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.analysisSupplyAreaStr(2)
                    },
                  },
                },
                [_vm._v("解析")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.supplyArea,
            callback: function ($$v) {
              _vm.supplyArea = $$v
            },
            expression: "supplyArea",
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.isNotEmpty(_vm.areaLimit)
                  ? _vm.limitAreaTableData
                  : _vm.areaTableData,
                size: "mini",
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "orderNo", label: "排序", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "area", label: "省份", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.area, function (item) {
                        return _c(
                          "el-checkbox",
                          {
                            staticStyle: { float: "left" },
                            attrs: { label: item.code },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "orderNo2", label: "排序", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { prop: "area2", label: "省份", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.area2, function (item) {
                        return _c(
                          "el-checkbox",
                          {
                            staticStyle: { float: "left" },
                            attrs: { label: item.code },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }